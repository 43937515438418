
import { computed, defineComponent, reactive, ref, onUpdated, onMounted } from "vue";
import store from "@/store";
import SurveyPlusSummaryStep from "./SurveyPlusSummaryStep.vue";
import { translateKey } from "@/hooks/TranslateService";
import bus from "vue3-eventbus";
  
export default defineComponent({
  name: "survey-plus-summary",
  props: ["isSummary"],
  components: {
    SurveyPlusSummaryStep,
  },
  setup() {
    const surveyPlusQuestionsLoading = ref(false);
    const surveyPlusQuestionList = reactive(
      computed(() => store.getters.getSurveyPlusQuestionList)
    );
    const surveyPlusStepList = reactive(
      computed(() => store.getters.getSurveyPlusStepList)
    );

    const getQuestionByStep = (step) => {
      if (!surveyPlusQuestionList.value) return;
      const newData = surveyPlusQuestionList.value.filter((item) => {
        return item.attributes.area.data.id == step;
      });
      return newData;
    };
  
    return {
      getQuestionByStep,
      surveyPlusStepList,
      surveyPlusQuestionsLoading,
      translateKey
    };
  },
});
