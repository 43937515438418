
import { defineComponent, ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import { Field, ErrorMessage } from "vee-validate";
import { translateKey } from "@/hooks/TranslateService";

export default defineComponent({
  name: "survey-plus-step",
  props: ["question", "onChange", "disableStep"],
  components: {
    Field,
    ErrorMessage,
  },
  setup(props) {
    const questionInfo = ref();
    const route = useRoute();

    onMounted(() => {
      questionInfo.value = props.question;
    });

    return {
      questionInfo,
      translateKey,
    };
  },
});
