import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, vModelRadio as _vModelRadio } from "vue"

const _hoisted_1 = { class: "w-100" }
const _hoisted_2 = { class: "pb-10 pb-lg-15" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "fv-row" }
const _hoisted_5 = {
  key: 0,
  class: "row mb-6"
}
const _hoisted_6 = { class: "col-lg-12 fv-row" }
const _hoisted_7 = {
  key: 0,
  class: "fv-row mb-15"
}
const _hoisted_8 = { class: "col-lg-12 fv-row" }
const _hoisted_9 = { class: "mt-10 d-flex align-items-center fs-6 fw-bold form-label mb-2 text-dark-blue" }
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = {
  key: 0,
  style: {"position":"relative","font-size":"inherit","color":"#F1416C","padding-left":"0.25rem","font-weight":"bold"}
}
const _hoisted_12 = { class: "fv-plugins-message-container" }
const _hoisted_13 = { class: "fv-help-block" }
const _hoisted_14 = { class: "align-items-center mt-3" }
const _hoisted_15 = ["value", "disabled", "onChange"]
const _hoisted_16 = { class: "ps-2 fs-6" }
const _hoisted_17 = {
  key: 1,
  class: "fv-row mb-15"
}
const _hoisted_18 = { class: "col-lg-12 fv-row" }
const _hoisted_19 = { class: "d-flex align-items-center fs-6 fw-bold form-label mb-2 text-dark-blue" }
const _hoisted_20 = ["innerHTML"]
const _hoisted_21 = {
  key: 0,
  style: {"position":"relative","font-size":"inherit","color":"#F1416C","padding-left":"0.25rem","font-weight":"bold"}
}
const _hoisted_22 = { class: "fv-plugins-message-container" }
const _hoisted_23 = { class: "fv-help-block" }
const _hoisted_24 = { class: "align-items-center mt-3" }
const _hoisted_25 = ["onChange", "disabled", "value"]
const _hoisted_26 = { class: "ps-2 fs-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_Field = _resolveComponent("Field")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.$route.params.step == 1)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "text-gray-600 fw-bold fs-6 mt-5 header-bg p-5",
            innerHTML: _ctx.$t('SURVEY_PLUS_1_MESSAGE_INTRODUCTION')
          }, null, 8, _hoisted_3))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_ctx.questionInfo)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              (
              _ctx.question.attributes.question_type === 'checkbox' &&
              _ctx.question.is_show === true
            )
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, [
                      _createElementVNode("label", _hoisted_9, [
                        _createElementVNode("span", {
                          class: "question-span",
                          innerHTML: _ctx.question['attributes'][_ctx.translateKey('question')]
                        }, null, 8, _hoisted_10),
                        (_ctx.question.attributes.mandatory)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_11, "* "))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_12, [
                        _createElementVNode("div", _hoisted_13, [
                          (_ctx.question.is_show)
                            ? (_openBlock(), _createBlock(_component_ErrorMessage, {
                                key: 0,
                                name: `${_ctx.questionInfo.attributes.uid}`
                              }, null, 8, ["name"]))
                            : _createCommentVNode("", true)
                        ])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_14, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.question.attributes.answers.data, (answer, index) => {
                        return (_openBlock(), _createElementBlock("label", {
                          key: index,
                          class: "mt-10 form-check form-check-inline form-check-solid me-5 col-lg-12 text-dark-blue"
                        }, [
                          _createVNode(_component_Field, {
                            name: `${_ctx.questionInfo.attributes.uid}`,
                            modelValue: _ctx.questionInfo.value,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.questionInfo.value) = $event))
                          }, {
                            default: _withCtx(() => [
                              _withDirectives(_createElementVNode("input", {
                                type: "checkbox",
                                class: "form-check-input",
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.questionInfo.value) = $event)),
                                value: answer.attributes.uid,
                                disabled: answer.disabled === true || _ctx.disableStep,
                                onChange: ($event: any) => (
                      _ctx.onChange(
                        _ctx.questionInfo,
                        answer.attributes.uid,
                        answer.attributes.is_single
                      )
                    )
                              }, null, 40, _hoisted_15), [
                                [_vModelCheckbox, _ctx.questionInfo.value]
                              ])
                            ]),
                            _: 2
                          }, 1032, ["name", "modelValue"]),
                          _createElementVNode("span", _hoisted_16, _toDisplayString(answer["attributes"][_ctx.translateKey("description")]), 1)
                        ]))
                      }), 128))
                    ])
                  ]))
                : _createCommentVNode("", true),
              (
              _ctx.question.attributes.question_type !== 'checkbox' &&
              _ctx.question.is_show === true
            )
                ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                    _createElementVNode("div", _hoisted_18, [
                      _createElementVNode("label", _hoisted_19, [
                        _createElementVNode("span", {
                          class: "question-span",
                          innerHTML: _ctx.question['attributes'][_ctx.translateKey('question')]
                        }, null, 8, _hoisted_20),
                        (_ctx.question.attributes.mandatory)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_21, "* "))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_22, [
                        _createElementVNode("div", _hoisted_23, [
                          (_ctx.question.is_show)
                            ? (_openBlock(), _createBlock(_component_ErrorMessage, {
                                key: 0,
                                name: `${_ctx.questionInfo.attributes.uid}`
                              }, null, 8, ["name"]))
                            : _createCommentVNode("", true)
                        ])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_24, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.question.attributes.answers.data, (answer, index) => {
                        return (_openBlock(), _createElementBlock("label", {
                          key: index,
                          class: "mt-10 form-check form-check-inline form-check-solid me-5 col-lg-12 text-dark-blue"
                        }, [
                          _createVNode(_component_Field, {
                            name: `${_ctx.questionInfo.attributes.uid}`,
                            modelValue: _ctx.questionInfo.value,
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.questionInfo.value) = $event))
                          }, {
                            default: _withCtx(() => [
                              _withDirectives(_createElementVNode("input", {
                                type: "radio",
                                class: "form-check-input",
                                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.questionInfo.value) = $event)),
                                onChange: ($event: any) => (
                      _ctx.onChange(
                        _ctx.questionInfo,
                        answer.attributes.uid,
                        answer.attributes.is_single
                      )
                    ),
                                disabled: answer.disabled === true || _ctx.disableStep,
                                value: answer.attributes.uid
                              }, null, 40, _hoisted_25), [
                                [_vModelRadio, _ctx.questionInfo.value]
                              ])
                            ]),
                            _: 2
                          }, 1032, ["name", "modelValue"]),
                          _createElementVNode("span", _hoisted_26, _toDisplayString(answer["attributes"][_ctx.translateKey("description")]), 1)
                        ]))
                      }), 128))
                    ])
                  ]))
                : _createCommentVNode("", true)
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}