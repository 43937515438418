
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
  getCurrentInstance
} from "vue";
import SurveyPlusStep from "@/components/wizard/steps/SurveyPlusStep.vue";
import SurveyConfirmModal from "@/components/wizard/SurveyConfirmModal.vue";
import { StepperComponent } from "@/assets/ts/components";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useForm } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import store from "@/store";
import { Actions } from "@/store/enums/StoreEnums";
import { useRouter, useRoute } from "vue-router";
import * as Yup from "yup";
import { translate, translateKey } from "@/hooks/TranslateService";
import SurveryPlusStructure from "@/views/divere/pages/survey-plus-structure/SurveryPlusStructure.vue";
import SurveyPlusSummary from "@/views/divere/pages/survey-plus-summary/SurveyPlusSummary.vue";
import Vue3Html2pdf from "vue3-html2pdf";
import { useI18n } from "vue-i18n";

interface IBackEndError {
  error: string;
  error_en: string;
}

export default defineComponent({
  name: "kt-survey-plus-vertical-wizard",
  components: {
    SurveyPlusStep,
    SurveyConfirmModal,
    SurveryPlusStructure,
    SurveyPlusSummary,
    Vue3Html2pdf,
  },
  setup() {
    const { t } = useI18n();
    const instance = getCurrentInstance();
    const pdfOptionsForSurveyStructure = ref({
      margin: [20, 15, 20, 15],
      html2canvas: {
        scrollX: 0,
        scrollY: 0
      }
    });

    const router = useRouter();
    const route = useRoute();
    const _stepperObj = ref<StepperComponent | null>(null);
    const verticalWizardRef = ref<HTMLElement | null>(null);
    const html2Pdf = ref<null | HTMLFormElement>(null);
    const html2PdfSummary = ref<null | HTMLFormElement>(null);
    const surveyStructureDownloadLoading = ref<boolean>(false);
    const surveySummaryDownloadLoading = ref<boolean>(false);
    const surveyConfirmModalRef =
      ref<InstanceType<typeof SurveyConfirmModal>>();

    const surveyPlusQuestionsLoading = ref(false);

    const isLastSubmit = ref(false);

    const getQuestionByStep = () => {
      if (!surveyPlusQuestionList.value) return;
      const newData = surveyPlusQuestionList.value.filter((item) => {
        return item.attributes.area.data.id == route.params.step;
      });
      return newData;
    };

    const checkBoxErrorMessage = ref<string>(translate("SELECT_ONE_OPTION"));
    const RadioErrorMessage = ref<string>(translate("SELECT_AN_OPTION"));

    const currentFormData = computed(() => {
      const createAccountSchema: any = {};
      const questions = getQuestionByStep();
      questions.map((item) => {
        let element_schema: any = null;
        if (item.attributes.mandatory === true) {
          if (item.attributes.question_type === "checkbox")
            element_schema = Yup.array().min(1, checkBoxErrorMessage.value);
          else element_schema = Yup.string().min(1, RadioErrorMessage.value);
        }
        if (item.is_show) createAccountSchema[`${item.attributes.uid}`] = element_schema;
      });
      return Yup.object().shape(createAccountSchema);
    });

    const surveyPlusStepList = reactive(
      computed(() => store.getters.getSurveyPlusStepList)
    );

    const surveyPlusFirstQuestion = reactive(
      computed(() => store.getters.getSurveyPlusFirstQuestion)
    );

    const surveyPlusQuestionList = reactive(
      computed(() => store.getters.getSurveyPlusQuestionList)
    );

    const surveyPlusResult = reactive(
      computed(() => store.getters.getSurveyPlusResult)
    );

    const isCompleted = reactive(
      computed(() => {
        if (surveyPlusResult.value) {
          return surveyPlusResult.value.company_year_plus_is_completed;
        }
        return false;
      })
    );

    const getQuestionsIDsInStep = (step: any) => {
      if (!surveyPlusQuestionList.value) return;
      const newData = surveyPlusQuestionList.value.filter((item) => {
        return item.attributes.area.data.id === parseInt(step);
      })
      return newData.map((item) => item.attributes.uid);
    };

    const activeSetId = reactive(
      computed(() => {
        if (surveyPlusResult.value) {
          return surveyPlusResult.value.active_set_id;
        }
        return false;
      })
    );

    const totalSteps = computed(() => {
      if (!_stepperObj.value) {
        return;
      }
      return _stepperObj.value.totatStepsNumber;
    });

    const current_yearly_period = reactive(
      computed(() => store.getters.getCompanyYearList.current_yearly_period)
    );

    watch([current_yearly_period], () => {
      if (current_yearly_period.value < 2021) {
        Swal.fire({
          text: t("NOT_CHANGE_YEAR_MESSAGE") + current_yearly_period.value + ".",
          icon: "warning",
          buttonsStyling: false,
          confirmButtonText: "Ok",
          customClass: {
            confirmButton: "btn btn-dark-blue",
          },
        });
        store.dispatch(Actions.CHANGE_CURRENT_YEARLY_PERIOD, 2021);
      }
    });

    const isAdmin = ref(computed(() => store.getters.isAdmin as boolean));

    const bullets = ref(
      computed(() => store.getters.getSurveyPlusBullets as Array<any>)
    );

    const getStepClass = (array, step) => {
      if      (step === parseInt(route.params.step as string)) return "current";
      else if (array[step - 1])                                return "completed";
      return "pending";
    }

    onMounted(() => {
      setTimeout(function () {
        _stepperObj.value = StepperComponent.createInsance(
          verticalWizardRef.value as HTMLElement
        );
      }, 3000);
      if (parseInt(route.params.step as string) !== 1) {
        if (isAdmin.value) {
          router.push({
            name: "AdminSurveyPlus",
            params: { step: 1 },
          });
        } else {
          router.push({
            name: "survey-plus",
            params: { step: 1 },
          });
        }
      }

      if (isAdmin.value) {
        store.dispatch(
          Actions.REQ_SURVEY_PLUS_BULLETS, 
          { 
            "user_id": route.params.id,
            "year": localStorage.getItem("to_year_admin")
          }
        );
      } else {
        store.dispatch(
          Actions.REQ_SURVEY_PLUS_BULLETS, 
          { 
            "year": localStorage.getItem("to_year")
          }
        );
      }

      setCurrentPageBreadcrumbs("List", ["Pages", "Survey plus"]);

      store.dispatch(Actions.REQ_SURVEY_PLUS_FIRST_QUESTION);
      store.dispatch(Actions.REQ_SURVEY_PLUS_STEP_LIST).then(() => {
        getSurveyPlusQuestionList();
      });

      setTimeout(() => {
        store.dispatch(
          Actions.CHANGE_CURRENT_YEARLY_PERIOD, 
          isAdmin.value ? localStorage.getItem("to_year_admin") : localStorage.getItem("to_year")
        )
      }, 500);
    });

    const getSurveyPlusQuestionList = () => {
      surveyPlusQuestionsLoading.value = true;
      store
        .dispatch(Actions.REQ_SURVEY_PLUS_RESULT, isAdmin.value ? { user_id: route.params.id }: {})
        .then((data) => {
          console.log(data);

          const firstQuestionResult = data["D0"] ? data["D0"] : undefined;
          let payload;

          if (firstQuestionResult) {
            if (
              firstQuestionResult[0]["answer_id"] == "D01" ||
              firstQuestionResult[0]["answer_id"] == "D02"
            )
              payload = 2;
            else payload = 1;
          } else payload = undefined;

          store
            .dispatch(Actions.REQ_SURVEY_PLUS_QUESTION_LIST, payload)
            .then(() => {
              if (payload)
                surveyPlusQuestionList.value.unshift(
                  surveyPlusFirstQuestion.value
                );

              initSurveyPlusQuestionList(data, undefined);
              surveyPlusQuestionsLoading.value = false;
            });
        })
        .catch(() => {
          surveyPlusQuestionsLoading.value = false;
        });
    };

    const initSurveyPlusQuestionList = (data, answerUid) => {
      surveyPlusQuestionList.value.forEach((question) => {
        const questionType = question.attributes.question_type;
        const questionUid = question.attributes.uid;
        const resultItem = data[questionUid] ? data[questionUid] : undefined;

        if (questionType === "checkbox") {
          if (resultItem) {
            const answers: any = [];
            resultItem.map((item) => {
              answers.push(item.answer_id);
            });
            question.value = answers;

            //<--check single-->
            if (answers.length === 1) {
              question.attributes.answers.data.forEach((answer) => {
                if (
                  answer.attributes.is_single === true &&
                  answer.attributes.uid === answers[0]
                ) {
                  question.attributes.answers.data.forEach((answer) => {
                    if (answer.attributes.is_single === true) {
                      answer.disabled = false;
                    } else answer.disabled = true;
                  });
                }
              });
            }
            //<--check single-->
          } else if (!resultItem) question.value = [];
        } else {
          if (question.id === 1) {
            if (answerUid) question.value = answerUid;
            else question.value = resultItem ? resultItem[0]["answer_id"] : "";
          } else question.value = resultItem ? resultItem[0]["answer_id"] : "";
        }

        //<--check showif, hideif condition-->
        const showifConditions = question.attributes.showif;
        const hideifConditions = question.attributes.hideif;

        question.is_show = true;

        if (showifConditions.length > 0) {
          question.is_show = false; //default

          for (let i = 0; i < showifConditions.length; i++) {
            if (!showifConditions[i].condition) continue;
            let vars = showifConditions[i].condition.split("="); //vars[0]: question uid, vars[1]: answer uid
            const result = data[vars[0]] ? data[vars[0]] : undefined;
            if (!result) {
              question.is_show = true;
              continue;
            }

            for (let j = 0; j < result.length; j++) {
              if (vars[1] === result[j].answer_id) {
                question.is_show = true;
                break;
              }
            }
          }
        }

        if (hideifConditions.length > 0) {
          question.is_show = true; //default

          for (let i = 0; i < hideifConditions.length; i++) {
            if (!hideifConditions[i].condition) continue;
            let vars = hideifConditions[i].condition.split("="); //vars[0]: question uid, vars[1]: answer uid
            const result = data[vars[0]] ? data[vars[0]] : undefined;
            if (!result) continue;

            for (let j = 0; j < result.length; j++) {
              if (vars[1] === result[j].answer_id) {
                question.is_show = false;
                break;
              }
            }
          }
        }
        //<--check showif, hideif condition-->
      });
    };

    const { values, handleSubmit, errors } = useForm<Record<string, unknown>>({
      validationSchema: currentFormData,
    });

    const handleStep = handleSubmit((values) => {
      submitSurveyInput();
      values.value = {};  
    });

    const onChange = (changedQuestion, answerUid, isSingle) => {
      //check first question
      if (changedQuestion.id === 1) {
        //first question changed
        const payload = answerUid == "D01" || answerUid == "D02" ? 2 : 1;
        surveyPlusQuestionsLoading.value = true;
        store
          .dispatch(Actions.REQ_SURVEY_PLUS_QUESTION_LIST, payload)
          .then(() => {
            if (payload)
              surveyPlusQuestionList.value.unshift(
                surveyPlusFirstQuestion.value
              );
            initSurveyPlusQuestionList(surveyPlusResult.value, answerUid);
            surveyPlusQuestionsLoading.value = false;
          });
      }

      surveyPlusQuestionList.value.forEach((question) => {
        //check single
        if (isSingle === true && question.id === changedQuestion.id) {
          if (changedQuestion.value.length === 0) {
            question.value = Array.isArray(question.value) ? [] : answerUid;
            question.attributes.answers.data.forEach((answer) => {
              answer["disabled"] = false;
            });
          } else {
            question.value = Array.isArray(question.value)
              ? [answerUid]
              : answerUid;
            question.attributes.answers.data.forEach((answer) => {
              if (answer.attributes.uid === answerUid) {
                answer["disabled"] = false;
              } else answer["disabled"] = true;
            });
          }
        }

        //check showif, hideif
        const showifConditions = question.attributes.showif;
        const hideifConditions = question.attributes.hideif;
        if (showifConditions.length > 0) {
          for (let i = 0; i < showifConditions.length; i++) {
            if (!showifConditions[i].condition) continue;
            let vars = showifConditions[i].condition.split("=");
            if (vars[0] === changedQuestion.attributes.uid) {
              if (vars[1] === answerUid) {
                question.is_show = true;
                break;
              } else {
                question.is_show = false;
              }
            }
          }
        }

        if (hideifConditions.length > 0) {
          for (let i = 0; i < hideifConditions.length; i++) {
            if (!hideifConditions[i].condition) continue;
            let vars = hideifConditions[i].condition.split("=");
            if (vars[0] === changedQuestion.attributes.uid) {
              if (vars[1] === answerUid) {
                question.is_show = false;
                break;
              } else {
                question.is_show = true;
              }
            }
          }
        }
      });
    };

    const submitSurveyInput = () => {
      if (!values) return false;

      let keys = Object.keys(values);
      let submitData = {};
      keys.forEach((question_uid) => {
        if (
          !getQuestionsIDsInStep(route.params.step).includes(question_uid) ||
          !isQuestionShown(question_uid)
        ) 
          return;

        let type = "";
        let question_type = "";
        let answers = [] as any;
        let company_size = null;

        for (let i = 0; i < surveyPlusQuestionList.value.length; i++) {
          if (
            surveyPlusQuestionList.value[i]["attributes"]["uid"] == question_uid
          ) {
            const result = values[question_uid];
            type =
              surveyPlusQuestionList.value[i]["attributes"]["type"]["data"][
                "attributes"
              ]["label"];
            question_type =
              surveyPlusQuestionList.value[i]["attributes"]["question_type"];
            company_size = surveyPlusQuestionList.value[i]["attributes"][
              "company_size"
            ]["data"]
              ? surveyPlusQuestionList.value[i]["attributes"]["company_size"][
                  "data"
                ]["attributes"]["Type"]
              : null;

            surveyPlusQuestionList.value[i]["attributes"]["answers"][
              "data"
            ].forEach((answer) => {
              if (typeof result === "string") {
                if (result === answer.attributes.uid) answers.push(answer);
              } else if (Array.isArray(result)) {
                if (result.includes(answer.attributes.uid))
                  answers.push(answer);
              }
            });
            break;
          }
        }

        submitData[question_uid] = {
          company_size: company_size,
          question_type: question_type,
          value: values[question_uid],
          type: type,
          answers: answers,
        };
      });

      store
        .dispatch(Actions.PUT_SURVEY_PLUS_ANSWERS, {
          data: {
            data: submitData,
            last_submit: isLastSubmit.value,
            step_number: route.params.step,
          },
          user_id: isAdmin.value ? route.params.id : ""
        })
        .then(() => {
          store.dispatch(Actions.REQ_SURVEY_PLUS_RESULT, isAdmin.value ? { user_id: route.params.id }: {});
          window.scrollTo({ top: 0, behavior: "smooth" });
          if (isAdmin.value) {
            if (parseInt(route.params.step as string) !== totalSteps.value) {
              router.push({
                name: "AdminSurveyPlus",
                params: { step: parseInt(route.params.step as string) + 1 },
              });
            } else {
              router.push({
                name: "survey-plus-report",
                params: { step: parseInt(route.params.step as string) + 1 },
              });
            }
          } else {
            if (parseInt(route.params.step as string) !== totalSteps.value) {
              router.push({
                name: "survey-plus",
                params: { step: parseInt(route.params.step as string) + 1 },
              });
            } else {
              router.push({
                name: "survey-plus-report",
              });
            }
          }

          if (isAdmin.value) {
            store.dispatch(
              Actions.REQ_SURVEY_PLUS_BULLETS, 
              { 
                "user_id": route.params.id,
                "year": localStorage.getItem("to_year_admin")
              }
            );
          } else {
            store.dispatch(
              Actions.REQ_SURVEY_PLUS_BULLETS, 
              { 
                "year": current_yearly_period.value
              }
            );
          }
        })
        .catch(() => {
          Swal.fire({
            text: "Submit Error",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: translate("TRY_AGAIN_SUBMIT_BUTTON"),
            customClass: {
              confirmButton: "btn fw-bold btn-dark-blue",
          },
        });
      });
    };

    const validationNavigate = (toStep) => {
      if (toStep > 12) {
        Swal.fire({
          text: t("DIALOG_WARNING_UNFINISHED_SURVEY_CONTENT"),
          icon: "warning",
          cancelButtonText: t("DIALOG_WARNING_UNFINISHED_SURVEY_BACK"),
          showCancelButton: true,
          buttonsStyling: false,
          confirmButtonText: "Ok",
          customClass: {
            confirmButton: "btn fw-bold btn-light-primary",
            cancelButton: "btn fw-bold btn-outline",
          },
        }).then(({ isConfirmed }) => {
          if (isConfirmed) {
            router.push({
              name: "dashboard",
            });
          }
        });
        return;
      } 

      if (toStep <= activeSetId.value) {
        if (isAdmin.value) {
          router.push({
            name: "AdminSurveyPlus",
            params: { step: toStep },
          });
        } else {
          router.push({
            name: "survey-plus",
            params: { step: toStep },
          });
        }
      } else {
        Swal.fire({
          text: t("DIALOG_WARNING_READ_ONLY_SET_OF_QUESTIONS_CONTENT"),
          icon: "warning",
          cancelButtonText: t("DIALOG_WARNING_READ_ONLY_SET_OF_QUESTIONS_BACK"),
          showCancelButton: true,
          buttonsStyling: false,
          confirmButtonText: "Ok",
          customClass: {
            confirmButton: "btn fw-bold btn-light-primary",
            cancelButton: "btn fw-bold btn-outline",
          },
        }).then(({ isConfirmed }) => {
          if (isConfirmed) {
            if (isAdmin.value) {
              router.push({
                name: "AdminSurveyPlus",
                params: { step: toStep },
              });
            } else {
              router.push({
                name: "survey-plus",
                params: { step: toStep },
              });
            }
          }
        });
      }
    };

    function isQuestionShown(question_uid: string) {
      for (let i = 0; i < surveyPlusQuestionList.value.length; i++) {
        if (surveyPlusQuestionList.value[i].attributes.uid === question_uid) {
          return surveyPlusQuestionList.value[i].is_show ? true : false
        }
      }
      return false;
    };

    const nextStep = () => {
      validationNavigate(parseInt(route.params.step as string) + 1);
    };

    const toogleConfirmModal = () => {
      surveyConfirmModalRef.value?.onShowModal();
    };

    const previousStep = () => {
      if (!_stepperObj.value) {
        return;
      }
      window.scrollTo({ top: 0, behavior: "smooth" });
      if (isAdmin.value) {
        router.push({
          name: "AdminSurveyPlus",
          params: { step: parseInt(route.params.step as string) - 1 },
        });
      } else {
        router.push({
          name: "survey-plus",
          params: { step: parseInt(route.params.step as string) - 1 },
        });
      }
    };

    const formSubmit = () => {
      isLastSubmit.value = true;
      handleStep();
    };

    const printResult = () => {
      if (html2Pdf.value) {
        surveyStructureDownloadLoading.value = true;
        html2Pdf.value.resetPagination();
        html2Pdf.value.downloadPdf();
      }
    };

    const onProgress = (event) => {
      if (event == 100) {
        surveyStructureDownloadLoading.value = false;
      }
    };

    const printSummary = () => {
      if (html2PdfSummary.value) {
        surveySummaryDownloadLoading.value = true;
        html2PdfSummary.value.resetPagination();
        html2PdfSummary.value.downloadPdf();
      }
    };

    const onPrintSummaryProgress = (event) => {
      if (event == 100) {
        surveySummaryDownloadLoading.value = false;
      }
    };

    // fetch the user information when params change
    watch(
      () => route.params.step,
      (step) => {
        if (!_stepperObj.value) {
          return;
        }
        _stepperObj.value.goto(parseInt(step as string));
      }
    );

    return {
      verticalWizardRef,
      previousStep,
      totalSteps,
      surveyPlusStepList,
      surveyPlusQuestionList,
      surveyPlusQuestionsLoading,
      nextStep,
      toogleConfirmModal,
      surveyConfirmModalRef,
      submitSurveyInput,
      formSubmit,
      handleStep,
      getQuestionByStep,
      onChange,
      currentFormData,
      translate,
      translateKey,
      surveyPlusFirstQuestion,
      isLastSubmit,
      errors,
      isCompleted,
      activeSetId,
      validationNavigate,
      pdfOptionsForSurveyStructure,
      onProgress,
      surveyStructureDownloadLoading,
      printResult,
      html2Pdf,
      html2PdfSummary,
      surveySummaryDownloadLoading,
      printSummary,
      onPrintSummaryProgress,
      getStepClass,
      bullets
    };
  },
});
