import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, vModelRadio as _vModelRadio } from "vue"

const _hoisted_1 = { class: "w-100" }
const _hoisted_2 = { class: "fv-row" }
const _hoisted_3 = {
  key: 0,
  class: "row mb-6 py-2"
}
const _hoisted_4 = { class: "col-lg-12 fv-row" }
const _hoisted_5 = {
  key: 0,
  class: "fv-row mb-15"
}
const _hoisted_6 = { class: "col-lg-12 fv-row" }
const _hoisted_7 = { class: "mt-10 d-flex align-items-center fs-6 fw-bold form-label mb-2" }
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = {
  key: 0,
  style: {"position":"relative","font-size":"inherit","color":"#F1416C","padding-left":"0.25rem","font-weight":"bold","margin-top":"25px"}
}
const _hoisted_10 = { class: "align-items-center mt-3" }
const _hoisted_11 = ["value"]
const _hoisted_12 = ["value"]
const _hoisted_13 = { class: "fw-bold ps-2 fs-6" }
const _hoisted_14 = {
  key: 1,
  class: "fv-row mb-15"
}
const _hoisted_15 = {
  class: "col-lg-12 fv-row",
  style: {"page-break-inside":"avoid"}
}
const _hoisted_16 = { class: "d-flex align-items-center fs-6 fw-bold form-label mb-2" }
const _hoisted_17 = ["innerHTML"]
const _hoisted_18 = {
  key: 0,
  style: {"position":"relative","font-size":"inherit","color":"#F1416C","padding-left":"0.25rem","font-weight":"bold","margin-top":"25px"}
}
const _hoisted_19 = {
  class: "align-items-center mt-3",
  style: {"page-break-inside":"avoid"}
}
const _hoisted_20 = ["value"]
const _hoisted_21 = ["value"]
const _hoisted_22 = { class: "fw-bold ps-2 fs-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.question && _ctx.questionInfo)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              (
                _ctx.question.attributes.question_type === 'checkbox' &&
                _ctx.question.is_show === true
              )
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("label", _hoisted_7, [
                        _createElementVNode("span", {
                          class: "question-span",
                          innerHTML: _ctx.question['attributes'][_ctx.translateKey('question')],
                          style: {"margin-top":"25px"}
                        }, null, 8, _hoisted_8),
                        (_ctx.question.attributes.mandatory)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_9, "* "))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_10, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.question.attributes.answers.data, (answer, index) => {
                        return (_openBlock(), _createElementBlock("label", {
                          key: index,
                          class: "mt-10 form-check form-check-inline form-check-solid me-5 col-lg-12 text-dark-blue"
                        }, [
                          _createVNode(_component_Field, {
                            name: `${_ctx.question.attributes.uid}`
                          }, {
                            default: _withCtx(() => [
                              (_ctx.isSummary)
                                ? _withDirectives((_openBlock(), _createElementBlock("input", {
                                    key: 0,
                                    type: "checkbox",
                                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.questionInfo.value) = $event)),
                                    class: "form-check-input",
                                    value: answer.attributes.uid
                                  }, null, 8, _hoisted_11)), [
                                    [_vModelCheckbox, _ctx.questionInfo.value]
                                  ])
                                : (_openBlock(), _createElementBlock("input", {
                                    key: 1,
                                    type: "checkbox",
                                    class: "form-check-input",
                                    value: answer.attributes.uid
                                  }, null, 8, _hoisted_12))
                            ]),
                            _: 2
                          }, 1032, ["name"]),
                          _createElementVNode("span", _hoisted_13, _toDisplayString(answer["attributes"][_ctx.translateKey("description")]), 1)
                        ]))
                      }), 128))
                    ])
                  ]))
                : _createCommentVNode("", true),
              (
                _ctx.question.attributes.question_type !== 'checkbox' &&
                _ctx.question.is_show === true
              )
                ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                    _createElementVNode("div", _hoisted_15, [
                      _createElementVNode("label", _hoisted_16, [
                        _createElementVNode("span", {
                          class: "question-span",
                          innerHTML: _ctx.question['attributes'][_ctx.translateKey('question')],
                          style: {"margin-top":"25px"}
                        }, null, 8, _hoisted_17),
                        (_ctx.question.attributes.mandatory)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_18, "* "))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_19, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.question.attributes.answers.data, (answer, index) => {
                        return (_openBlock(), _createElementBlock("label", {
                          key: index,
                          class: "mt-10 form-check form-check-inline form-check-solid me-5 col-lg-12 text-dark-blue"
                        }, [
                          _createVNode(_component_Field, {
                            name: `${_ctx.question.attributes.uid}`
                          }, {
                            default: _withCtx(() => [
                              (_ctx.isSummary)
                                ? _withDirectives((_openBlock(), _createElementBlock("input", {
                                    key: 0,
                                    type: "radio",
                                    class: "form-check-input",
                                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.questionInfo.value) = $event)),
                                    value: answer.attributes.uid
                                  }, null, 8, _hoisted_20)), [
                                    [_vModelRadio, _ctx.questionInfo.value]
                                  ])
                                : (_openBlock(), _createElementBlock("input", {
                                    key: 1,
                                    type: "radio",
                                    class: "form-check-input",
                                    value: answer.attributes.uid
                                  }, null, 8, _hoisted_21))
                            ]),
                            _: 2
                          }, 1032, ["name"]),
                          _createElementVNode("span", _hoisted_22, _toDisplayString(answer["attributes"][_ctx.translateKey("description")]), 1)
                        ]))
                      }), 128))
                    ])
                  ]))
                : _createCommentVNode("", true)
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}