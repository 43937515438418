
  import { defineComponent, ref, onMounted } from "vue";
  import { translate, translateKey } from "@/hooks/TranslateService";
  
  export default defineComponent({
    name: "survey-plus-summary-step",
    props: ["question", "isSummary"],
    setup(props) {
      const questionInfo = ref();
  
      onMounted(() => {
        questionInfo.value = props.question;
      });
  
      return {
        questionInfo,
        translateKey,
        translate,
      };
    },
  });
  